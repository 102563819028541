import Vue from "vue";
import VueRouter from "vue-router";

//Routes
import Dashboard from "./routes/dashboard";
import Auth from "./routes/auth";
import General from "./routes/general";
import Administration from "./routes/administration";
import Promotion from "./routes/promotion";
import Transaction from "./routes/transaction";
import Audit from "./routes/audit";
import Lottery from "./routes/lottery";
import CRM from "./routes/crm";
import Report from "./routes/report";
import Member from "./routes/member";
import SEO from "./routes/seo";

import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...Dashboard,
    ...Auth,
    ...General,
    ...Administration,
    ...Promotion,
    ...Transaction,
    ...Audit,
    ...Lottery,
    ...CRM,
    ...Report,
    ...Member,
    ...SEO,
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
