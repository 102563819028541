import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faCopy,
  faPiggyBank,
  faUser,
  faUserTie,
  faBuildingColumns,
  faPercent,
  faDesktop,
  faMoneyBillTransfer,
  faReceipt,
  faStore,
  faUsers,
  faStar,
  faFileInvoiceDollar,
  faVault,
  faFile,
  faDiceSix,
  faTicket,
  faBoxArchive,
  faMagnifyingGlass,
  faFileLines,
  faBalanceScale,
  faGear,
  faBook,
  faBullseye,
  faWrench,
  faAt,
  faShuffle,
  faUsersRectangle,
  faListCheck
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDesktop,
  faCopy,
  faPiggyBank,
  faUser,
  faUserTie,
  faBuildingColumns,
  faPercent,
  faMoneyBillTransfer,
  faReceipt,
  faStore,
  faUsers,
  faStar,
  faFileInvoiceDollar,
  faVault,
  faFile,
  faDiceSix,
  faTicket,
  faBoxArchive,
  faMagnifyingGlass,
  faBalanceScale,
  faFileLines,
  faGear,
  faBook,
  faBullseye,
  faWrench,
  faAt,
  faShuffle,
  faUsersRectangle,
  faListCheck
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
