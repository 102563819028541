export default [
  {
    path: "/seo/agent/performance",
    name: "seo-agent-performance",
    component: () => import("@/views/seo/AgentPerformance.vue"),
    meta: {
      pageTitle: "SERP Agent Performance",
      breadcrumb: [
        {
          text: "SEO",
          active: false
        },
        {
          text: "Agent Performance",
          active: true
        }
      ]
    }
  },
  {
    path: "/seo/keyword",
    name: "seo-keyword",
    component: () => import("@/views/seo/KeywordAnalysis.vue"),
    meta: {
      pageTitle: "SERP Keyword Analysis",
      breadcrumb: [
        {
          text: "SEO",
          active: false
        },
        {
          text: "Keyword Analysis",
          active: true
        }
      ]
    }
  },
  {
    path: "/seo/configuration",
    name: "seo-configuration",
    component: () => import("@/views/seo/Configuration.vue"),
    meta: {
      pageTitle: "SEO Configuration",
      breadcrumb: [
        {
          text: "Dashboard",
          active: false,
          to: { name: `dashboard` }
        },
        {
          text: "SEO",
          active: true
        },
        {
          text: "Configuration",
          active: true
        }
      ]
    }
  }
];
