import Vue from "vue";
import { mapGetters } from "vuex";

Vue.mixin({
  data() {
    return {
      vueNumericConfig: {
        minus: true,
        precision: 2
      },
      trxTypeDefaults: {
        dp: "DP",
        wd: "WD",
        adj: "ADJUSTMENT",
        trf: "TRF",
        wdFee: "WDFEE",
        startingBalance: "STARTINGBALANCE"
      },
      nexusPayment: {
        bankName: "NEXUSPAY DEPOSIT EMONEY",
      },
      userSystem:"SYSTEM",
      typeTaskList: [
        { id: 1, description: "Member Non Deposit" },
        { id: 2, description: "Member New" },
        { id: 3, description: "Member Active" }
      ],
      statusTaskList: [
        { id: 1, description: "Pending" },
        { id: 2, description: "Closed by System" },
        { id: 3, description: "Responded" },
        { id: 4, description: "No Respond" },
        { id: 5, description: "Invalid Number" },
        { id: 6, description: "DnD" },
        { id: 7, description: "Closed by Other" },
        { id: 8, description: "Timeout" },
      ],
      monthOpts: [
        { key: 1, label: `January` },
        { key: 2, label: `February` },
        { key: 3, label: `March` },
        { key: 4, label: `April` },
        { key: 5, label: `May` },
        { key: 6, label: `June` },
        { key: 7, label: `July` },
        { key: 8, label: `August` },
        { key: 9, label: `September` },
        { key: 10, label: `October` },
        { key: 11, label: `November` },
        { key: 12, label: `December` }
      ],      
      yearOpts: [
        { key: 2024, label: `2024` },
        { key: 2025, label: `2025` },
        { key: 2026, label: `2026` },
        { key: 2027, label: `2027` },    
        { key: 2028, label: `2028` },    
        { key: 2029, label: `2029` },      
        { key: 2030, label: `2030` },
        { key: 2031, label: `2031` },
        { key: 2032, label: `2032` },
        { key: 2033, label: `2033` },
        { key: 2034, label: `2034` },
      ],
    };
  },
  computed: {
    ...mapGetters(`oidcStore`, ["oidcUser"])
  },
  methods: {
    updateCollection(collection, keyCol, newRecord) {
      let idx = collection.findIndex(x => x[keyCol] == newRecord[keyCol]);
      collection[idx] = newRecord;
      return collection;
    },
    updateCollectionByIdx(collection, idx, newRecord) {
      collection[idx] = newRecord;
      return collection;
    },
    removeFromCollection(collection, keyCol, keyValue) {
      let idx = collection.findIndex(x => x[keyCol] == keyValue);
      collection.splice(idx, 1);
    },
    clearObject(obj) {
      for (var p in obj) {
        obj[p] = null;
      }
    },
    download(stream, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([stream]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    slugify(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
    loadPeriodTemplate() {
      let periodArray = [];

      let today = this.$moment().startOf("day");
      let yesterday = this.$moment(today).subtract(1, `days`);
      let thisWeek = this.$moment().startOf("week");
      let lastWeek = this.$moment(thisWeek).subtract(1, `weeks`);
      let thisMonth = this.$moment().startOf("month");
      let lastMonth = this.$moment(thisMonth).subtract(1, `months`);

      periodArray.push({ text: `Custom Date`, value: { from: today, to: today } });
      periodArray.push({ text: `Today`, value: { from: today, to: today } });
      periodArray.push({ text: `Yesterday`, value: { from: yesterday, to: yesterday } });
      periodArray.push({ text: `This Week`, value: { from: thisWeek, to: today } });
      periodArray.push({ text: `Last Week`, value: { from: lastWeek, to: this.$moment(thisWeek).subtract(1, `days`) } });
      periodArray.push({ text: `This Month`, value: { from: thisMonth, to: today } });
      periodArray.push({ text: `Last month`, value: { from: lastMonth, to: this.$moment(thisMonth).subtract(1, `days`) } });

      return { today, periodArray };
    },
    successCopy() {
      this.showToast(`success`, `Copied to clipboard!`);
    },
    isInRoles(roleList) {
      let role = this.oidcUser.role.toLowerCase();
      let allowed = roleList.indexOf(role) > -1;
      if (allowed) return true;
      return false;
    },
    pascalCase(str) {
      return str.toString().replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
    },
    dayConverter(dayNumber) {
      let dayInt = parseInt(dayNumber);
      switch (dayInt) {
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
        case 0:
          return "Sunday";
      }
    },
    typeofTask(id) {
      return this.typeTaskList.find(task => task.id === id).description;
    },
    typeofStatus(id) {
      return this.statusTaskList.find(task => task.id === id).description;
    }
  }
});
